<template>
  <div class="container">
    <NavBar />
    <div class="box">
      <van-field v-model="realName" placeholder="输入真实姓名" />
      <van-field v-model="idCard" placeholder="输入身份证号码" />
    </div>
    <van-button
      class="btn"
      round
      :color="disableColor"
      :loading="loading"
      loading-text="认证"
      block
      @click="submit"
      >认证</van-button
    >
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { idCardReg } from '@/utils/reg.js'
import { realName } from '@/api/user.js'
export default {
  components: {
    NavBar
  },
  data() {
    return {
      realName: '',
      idCard: '',
      loading: false
    }
  },
  computed: {
    disableColor() {
      if (this.realName === '' || this.idCard === '') {
        return '#413C33'
      } else {
        return '#CB7E0E'
      }
    }
  },
  methods: {
    submit() {
      if (this.realName === '' || this.idCard === '') {
        this.$toast('请完善认证信息')
        return false
      }
      let flag = idCardReg.test(this.idCard)
      if (this.idCard && flag) {
        this.loading = true
        realName({ idCard: this.idCard, realName: this.realName })
          .then(async (res) => {
            this.loading = false
            if (res.status === 200) {
              this.$toast.success('成功')
              await this.$store.dispatch('getUserInfo')
              this.$router.go(-1)
            }
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        this.$toast('填写正确的身份证号')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.btn {
  width: 313px;
  height: 42px;
  margin: 50px auto;
}
.box {
  margin-top: 10px;
  .cell {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 44px;
    padding: 12px 16px;
    background-color: @bg-dark;
    .label {
      font-size: 14px;
      color: @text-color-1;
    }
    .value {
      display: flex;
      align-items: center;
      font-size: 14px;
      span {
        display: inline-block;
        color: @text-color-2;
        margin-right: 4px;
      }
    }
  }
}
</style>
